import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter"
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  addNewCategory as onAddNewCategory,
  deleteCategory as onDeleteCategory,
  getCategories as onGetCategories,
  updateCategory as onUpdateCategory
} from "store/category/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Code, Description, Name } from "./categoryCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";

const Category = props => {

  //meta title
  document.title = `CRM | ${props.t("Categories")}`;

  const dispatch = useDispatch();

  const { categories,error } = useSelector(state => ({
    categories: state.categories.categories,
    error: state.categories.error
  }));

  const [modal, setModal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [category, setCategory] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [status, setStatus] = useState({ label: "", value: "" });

  // validation
  const validation = {

    values: {
      name: (category && category.name) || "",
      code: (category && category.code) || "",
      status: (category && category.status) || "",
      description: (category && category.description) || ""
    },

    handleSubmit: (values) => {
      if (isEdit) {
        const updateCategory = {
          id: category ? category.id : 0,
          name: values.name,
          code: values.code,
          status: values.status,
          description: values.description
        };
        // update category
        dispatch(onUpdateCategory(updateCategory));
      } else {
        const newCategory = {
          name: values["name"],
          code: values["code"],
          status: values["status"],
          description: values["description"]
        };
        // save new category
        dispatch(onAddNewCategory(newCategory));
      }
      toggle();
    }
  };

  const statusOptions = [{ label: props.t("ACTIVE"), value: "ACTIVE" },
    { label: props.t("PASSIVE"), value: "PASSIVE" }];

  const handleCategoryClick = arg => {
    const category = arg;

    setCategory({
      id: category.id,
      name: category.name,
      code: category.code,
      status: category.status,
      description: category.description
    });

    setStatus({ label: `${props.t(category.status)}`, value: category.status });
    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Code")}`,
        Header: "Kod",
        accessor: "code",
        filterable: true,
        Cell: (cellProps) => {
          return <Code {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Status")}`,
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.status}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel:`${props.t("Deletable")}`,
        Header: 'Deletable',
        accessor: 'deletable',
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const categoryData = cellProps.row.original;
                  handleCategoryClick(categoryData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const categoryData = cellProps.row.original;
                  onClickDelete(categoryData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCategory(null);
      setStatus("");
    } else {
      setModal(true);
    }
  };

  //delete category
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (category) => {
    setCategory(category);
    setDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    if (category.id) {
      dispatch(onDeleteCategory(category.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetCategories());
      setFirstCall(false);
    }
  }, [dispatch, categories]);

  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(categories)) {
      setCategoryList(categories);
    }
  }, [categories]);

  const handleCategoryClicks = () => {
    setCategoryList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetCategories());
  };

  const handleChange = (e) => {
    setCategory({
      ...category,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  function onChangeStatus(e) {
    setStatus(e);
    setCategory({
      ...category,
      ["status"]: e.value
    });
  }

  useEffect(() => {
    if (error !== undefined && Object.entries(error).length > 0 && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Category")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={categories}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleCategoryClicks}
                    handleCategoryClick={handleCategoryClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.name || ""}
                                invalid={!validation.values.name}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Code")}</Label>
                              <Input
                                name="code"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.code || ""}
                                invalid={!validation.values.code}
                                required={true}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.description || ""}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Status")}</Label>
                              <Select
                                id="integration-status"
                                className="bs-select"
                                value={status}
                                onChange={(e) => {
                                  onChangeStatus(e);
                                }}
                                options={statusOptions}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Category.propTypes = {
  t: PropTypes.any,
  categories: PropTypes.array,
  onGetCategories: PropTypes.func,
  onAddNewCategory: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  onUpdateCategory: PropTypes.func
};

export default withRouter(withTranslation()(Category));

